import * as React from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Providing dyslexic learners and their families with the services they need to thrive." />
    <div className="blueBG">
      <Container className="">
        <h1 className="mb-4">
        Supporting Dyslexic Learners and Their Parents
        </h1>
        <div className="text-center mb-4">
          <Link to="/services/" className="btn btn-outline-light me-3">Learn More</Link>
          <Link to="/contact/" className="btn btn-light">Contact Us</Link>
        </div>
        <p className="lead text-center">
      We educate and empower parents as they navigate the complex special education system.
      </p>
        <div className="text-center move-image-up">
        <StaticImage
          src="../images/child-wide-horizontal.jpg"
          loading="eager"
          width={900}
          quality={45}
          className="box-shadow"
          formats={["auto", "webp", "avif"]}
          alt="young child writing a letter with parent watching from behind"
        />
        </div>
      </Container>
    </div>
    <Container className="move-image-up-margin-below">
      
      <Row className="g-4 text-center">
        <Col lg={4}>
          <Card className="h-100">
            <Card.Body className="lead">Do you have a struggling reader?</Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Body className="lead">Do you need help getting your child qualified for Special Education?</Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Body className="lead">Could you use a parent coach or advocate during IEP meetings?</Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="two-rem-padding">
        <p className="lead text-center"><strong>We can help!</strong></p>
        <p className="lead text-center mt-2"><Link to="/contact/" className="btn btn-outline-primary"><strong>Get started now</strong></Link></p>
      </div>
      <Row className="g-4 two-rem-padding align-items-center">
        <Col lg={6} className="align-items-center">
          <p className="mission-text">
            <em>Our mission is to partner with parents to ensure that each child develops to their fullest potential.</em> 
          </p>
        </Col>
        <Col lg={6}>
          <div>
            <StaticImage
              src="../images/child-at-desk.jpg"
              loading="eager"
              width={900}
              quality={45}
              formats={["auto", "webp", "avif"]}
              alt="young child sitting at a desk"
            />
          </div>
        </Col>
      </Row>

    </Container>
    <Container className="singleCol">
      
    <hr />
    <blockquote>
        <p>
        Julia’s advocacy and goal writing skills have assisted us with IEP meetings. She has helped us reviewing the many pages of assessments and goals written by the school. Her attention to detail helped us catch things a parent wouldn’t notice. Julia knows his needs and is able to help me rewrite his goals to better fit his specific needs."
        </p>
       
        <cite>-	Jennifer and Robert H., Kentfield, CA.</cite>
      </blockquote>
      <hr />
    </Container>
    <Container className="singleCol two-rem-padding">
      
      {/* <p>
        IDSS, located in the San Francisco Bay Area, supports and advises dyslexic learners and their families.  
      </p>
      <p>
        We partner with parents and school districts to ensure that each student’s individual needs are being met. In direct collaboration with parents, we assess whether the student has access to the services and accommodations they need within their school to develop to their fullest potential. We provide coaching to empower parents to understand their rights and to become active and informed members of their student’s Individualized Education Team (IEP).
      </p>
      <p>
        With our expertise in special education law, we help families develop a comprehensive plan for success.  When needed, we attend IEP meetings with parents to advocate directly on the student’s behalf.
      </p>
      <p>
        For students, dyslexia screenings and remediation in Structured Literacy using the Slingerland® Approach are also offered. 
      </p> */}
      <p className="text-center">
        <StaticImage
          src="../images/ceri-certified.jpg"
          loading="eager"
          width={200}
          quality={65}
          class="me-3"
          formats={["auto", "webp", "avif"]}
          alt="CERI Certified Logo"
        />
        <StaticImage
          src="../images/dte-logo.jpg"
          loading="eager"
          width={200}
          quality={65}
          formats={["auto", "webp", "avif"]}
          alt="DTE Logo"
        />
      </p>

      <blockquote>
        <p>
          One thing we know for certain about dyslexia is that this is one small area of difficulty in a sea of strengths."
        </p>
        <cite>-	Dr. Sally Shaywitz, Co-Director, Yale Center for Dyslexia & Creativity</cite>
      </blockquote>
    </Container>
  </Layout>
)

export default IndexPage
